import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'

import Layout from '../layouts/layout'
import ToTop from '../layouts/totop.js'

import './blog-post-markdown.css'
import './blog-post.css'
import './blog-post-sticky.css'
import Wave from '../components/wave.js'
import WaveMulti from '../components/wavemulti.js'

import Toc from 'toc-maker'

function waitForGlobal(name, timeout = 300) {
  return new Promise((resolve, reject) => {
    let waited = 0

    function wait(interval) {
      setTimeout(() => {
        waited += interval
        // some logic to check if script is loaded
        if (window[name] !== undefined) {
          return resolve()
        }
        if (waited >= timeout * 1000) {
          return reject({ message: 'Timeout' })
        }
        wait(interval * 2)
      }, interval)
    }

    wait(30)
  })
}

class BlogPostTemplate extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      showMenu: false,
      showPostIt: true,
      offset: null,
      tocFromTop: null,
      reachBottomEver: false,
      firstTime: true,
    };
  }

  async componentDidMount() {
    window.addEventListener('resize',this.handleResize);
    window.addEventListener("scroll", this.handleScroll);

    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))) {
      this.setState({ showPostIt: false })
    }
    
    this.handleOffset();
    
    this.renderCode();
    this.renderFormula();
    this.renderToc();
    this.renderCardLink();

  }

  componentWillUnmount() {       
    window.removeEventListener('resize',this.resize);
  }

  /********************** RENDER SOECIAL COMPONENTS ****************************/
  async renderCode() {
    // 渲染代码
    try {
      const deckdeckgoHighlightCodeLoader = require("@deckdeckgo/highlight-code/dist/loader");
      await deckdeckgoHighlightCodeLoader.defineCustomElements(window);
    } catch (err) {
        console.error(err);
    }
  }

  renderFormula() {
    waitForGlobal('MathJax').then(() => {
      window.top.MathJax.Hub.Config({
        tex2jax: {
          inlineMath: [['$', '$'], ['\\(', '\\)']],
          displayMath: [['$$', '$$'], ['[', ']']],
          processEscapes: true,
          processEnvironments: true,
          skipTags: ['script', 'noscript', 'style', 'textarea', 'pre'],
          TeX: {
            equationNumbers: { autoNumber: 'AMS' },
            extensions: ['AMSmath.js', 'AMSsymbols.js'],
          },
        },
      })
    });
    if (window.top.MathJax != null) {
      window.top.MathJax.Hub.Queue(['Typeset', window.top.MathJax.Hub])
    }
  }

  renderToc() {
    let options = {
      nullTitle: "空标题"
    };
    var toc = new Toc(document.querySelector("#blog-md"), options);
    var counter = 0;
    for(var node in toc.headers){
      if(node.localName=="h1"){
        counter++;
        if(counter == 2){ break; }
      }
    }
    if(counter < 2 && toc.headers[0].localName=="h1"){ 
      toc.tocEl = toc.tocEl.firstElementChild.lastElementChild;
    }

    document.querySelector("#toc").innerHTML = "";
    var title = document.createElement("h3");
    title.innerHTML = "Table of Content";
    title.classList.add("title");
    document.querySelector("#toc").append(title);
    document.querySelector("#toc").append(toc.tocEl);
  }

  renderCardLink() {
    document.querySelectorAll(".markdown p > a").forEach((e, index)=> {
      if(e.parentNode.firstElementChild==e && e.parentNode.lastElementChild==e){
        let elem = e.parentNode;
        let href = e.href;
        let text = e.innerHTML;

        let arrUrl = href.split("//");
        let start = arrUrl[1].split("/")[0];
        let domainHref = arrUrl[0] + "//" + start;
                
        elem.innerHTML = [
        "<a href=\"" + href + "\" class=\"LinkCard\">\n" +
        "   <span class=\"LinkCard-backdrop\"></span>\n" +
        "   <span class=\"LinkCard-content\">\n" +
        "       <span class=\"LinkCard-text\">\n" +
        "       <span class=\"LinkCard-title\">" + text + "</span>\n" +
        "       <span class=\"LinkCard-meta\">\n" +
        "           <svg class=\"Zi Zi--InsertLink\" fill=\"currentColor\" viewBox=\"0 0 24 24\"><path d=\"M13.414 4.222a4.5 4.5 0 1 1 6.364 6.364l-3.005 3.005a.5.5 0 0 1-.707 0l-.707-.707a.5.5 0 0 1 0-.707l3.005-3.005a2.5 2.5 0 1 0-3.536-3.536l-3.005 3.005a.5.5 0 0 1-.707 0l-.707-.707a.5.5 0 0 1 0-.707l3.005-3.005zm-6.187 6.187a.5.5 0 0 1 .638-.058l.07.058.706.707a.5.5 0 0 1 .058.638l-.058.07-3.005 3.004a2.5 2.5 0 0 0 3.405 3.658l.13-.122 3.006-3.005a.5.5 0 0 1 .638-.058l.069.058.707.707a.5.5 0 0 1 .058.638l-.058.069-3.005 3.005a4.5 4.5 0 0 1-6.524-6.196l.16-.168 3.005-3.005zm8.132-3.182a.25.25 0 0 1 .353 0l1.061 1.06a.25.25 0 0 1 0 .354l-8.132 8.132a.25.25 0 0 1-.353 0l-1.061-1.06a.25.25 0 0 1 0-.354l8.132-8.132z\"></path></svg>\n" +
        "           <span>" + domainHref + "</span>\n" +
        "       </span>\n" +
        "   </span>\n" +
        "   </span>\n" +
        "</a>"].join("");

        elem.firstElementChild.firstElementChild.style.backgroundImage = `url("https://picsum.photos/seed/` + Date.parse(new Date()) + index + `/390/200")`;
      }
    });
  }

  /********************** HANDLE USER INTERACTION *****************************/
  handleScroll = (event) => {
    var toc = document.getElementById("toc");

    const scrollTop = window.pageYOffset;
    if(this.state.firstTime && scrollTop > 50 && !(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))) {
      if(!this.state.showMenu){
        this.setState({ 
          showMenu: true,
          firstTime: false
        })
      }
    }

    if(this.state.showMenu){
      var navHeight = document.getElementById("Navigation").offsetHeight;
      if(window.pageYOffset >= this.state.tocFromTop - navHeight){
        if(!this.state.reachBottomEver){
          toc.style.position = "fixed";
          toc.style.top = navHeight + "px";

          this.setState({ reachBottomEver: true });
        }

      } else {
        if(this.state.reachBottomEver){
          this.handleResize();
          setTimeout(() => {
            toc.style.position = "absolute";
            toc.style.top = "0";
          }, 1000);

          this.setState({ reachBottomEver: false });
        }

        // toc.style.position = "static";

        toc.style.position = "absolute";
        toc.style.top = "0";
        // toc.style.top = this.state.tocFromTop + "px";
      }
    }
  }

  handleResize = (event) => {
    this.setState({ showMenu: false });
    this.handleOffset();
  }

  handleOffset(){
    var marginRight = window.innerWidth*(1-0.8)*0.5;
    if(window.screen.width > 1300) {
      this.setState({ offset:  marginRight- 20 });
    } else if(window.screen.width > 1000) {
      this.setState({ offset: marginRight - 5 });
    }

    this.setState({ tocFromTop: document.getElementById("blog-content").offsetTop });
    this.setState({ offset: window.screen.width * 0.1 });
  }

  handleMenuClick = (event) => {
    this.setState({ showMenu: !this.state.showMenu });
  }

  handlePostItClick = (event) => {
    this.setState({ showPostIt: !this.state.showPostIt });
  }

  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    const socialmedias = get(this, 'props.data.allContentfulSocialMedia.edges')

    // this.setState({ description: post.description.childMarkdownRemark.html })
    
    return (
      <Layout location={this.props.location} socialmedias={socialmedias}>

        <div style={{ background: '#fff' }}>
          <Helmet title={`${post.title} | ${siteTitle}`}>
            <link rel="stylesheet" href="//at.alicdn.com/t/c/font_1802141_kk39jwuzmbs.css" />
            <script
                type="text/javascript"
                src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-AMS-MML_HTMLorMML"
                async
            />
          </Helmet>
          {/* <div className="blog-basic" style={{backgroundImage: `url(${post.heroImage.fluid.src})`}}> */}
          <div className="blog-basic">
            <img className="blog-bg" src={post.heroImage.fluid.src} loading="eager" draggable="false"></img>
            <h1 className="blog-title">{post.title}</h1>
            <p className="blog-date">🗓️ {post.publishDate}</p>
            <div className="preview-tags">
            {post.tags &&
              post.tags.map(tag => (
                <label className="preview-tag" key={tag}>
                  🔖 {tag}
                </label>
            ))}
            </div>
            
            <div className="blog-sticky" style={{
              opacity: this.state.showPostIt ? "1" : "0"
            }}>
              <div className="todo-group">
                    <div className="todo-paper">
                        <div className="todo-paper-bg">
                        </div>
                    </div>
                    <div className="cover-content-container">
                        <div className="cover-content">
                            <div className="todo-text" 
                            dangerouslySetInnerHTML={{
                              __html: post.description.childMarkdownRemark.html,
                            }}></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Wave waveheight="172" /> */}
            <WaveMulti />
          </div>
          
          <div className="blog-content" id="blog-content">
            <div className="blog-toc" id="toc" style={{ 
              width: `${2*this.state.offset}px`,
              transform: this.state.showMenu ?  'none' : `translateX(-${2*this.state.offset}px)`,
              opacity: this.state.showMenu ? '1' : '0',
              }}>
            </div>
            <div className="markdown" id="markdown" style={{ 
              transform: this.state.showMenu? `translateX(${this.state.offset}px)` : 'none'
            }}>
                <div className="blog-menu">
                  <div>
                    <i className={this.state.showMenu ? "iconfont icon-zuojiantou" : "iconfont icon-mulu"} onClick={this.handleMenuClick}></i>
                  </div>
                  <div style={{ transform: this.state.showPostIt ? "rotate(30deg)" : "rotate(50deg)" }}>
                    <i className="iconfont icon-bianqian" onClick={this.handlePostItClick}></i>
                  </div>
                </div>
                <div id="blog-md"
                dangerouslySetInnerHTML={{
                    __html: post.body.childMarkdownRemark.html,
                }}
                />
            </div>
            <div className="blog-content-mask"></div>
          </div>
          
          <ToTop />
        </div>


      </Layout>
    )
  }

  componentDidUpdate() {
    if (window.top.MathJax != null) {
      window.top.MathJax.Hub.Queue(['Typeset', window.top.MathJax.Hub])
    }
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      tags
      publishDate(formatString: "YYYY-MM-DD")
      heroImage {
        fluid(maxWidth: 1000) {
            sizes
            src
            srcSet
          }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulSocialMedia (sort: { fields: [orderRank, createdAt], order: ASC }) {
      edges {
        node {
          fontclass
          color
          url
          title
          orderRank
        }
      }
    }
  }
`

import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import base from '../pages/base.css'
import Container from '../components/container'
import Navigation from './navigation'
import SocialMedias from '../components/socialmedias'
import Footer from '../layouts/footer'

class Template extends React.Component {
  constructor(props) {
    super(props)
  }


  render() {
    const { location, socialmedias, children } = this.props

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <Container>
        <Navigation />
        {children}

        <SocialMedias data={socialmedias} />

        <Footer showRecord={true} />

        {/* <Link className="NavigatorAnchor" id="bottom">bottom</Link> */}
      </Container>
    )
  }
}

export default Template

export const pageQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulSocialMedia (sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          fontclass
          color
          url
          title
        }
      }
    }
  }
`
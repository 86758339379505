import React from 'react'
import { Link } from 'gatsby'
import './totop.css'

class ToTop extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            hasScrolled: false,
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }
  
    handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if(scrollTop > 50) {
            this.setState({ hasScrolled: true });
        } else {
            this.setState({ hasScrolled: false });
        }
    }

    render() {
        return (
            <div className="ToTopGroup" className={this.state.hasScrolled ? 'ToTopGroup ToTopGroupScrolled' : 'ToTopGroup'}>
                <a className="inner-container" href="#">
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                        <linearGradient id="black_purple" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#050A27"/>
                            <stop offset="75%" stopColor="#4A54BC"/>
                        </linearGradient>
                        </defs>
                        <path d="M952.32 419.84L522.24 3.413333c-6.826667-6.826667-17.066667-6.826667-23.893333 0L71.68 419.84C30.72 457.386667 30.72 525.653333 68.266667 563.2c37.546667 40.96 105.813333 40.96 143.36 3.413333l177.493333-170.666666v505.173333c0 64.853333 54.613333 119.466667 119.466667 119.466667s119.466667-54.613333 119.466666-119.466667V399.36l177.493334 170.666667c20.48 17.066667 44.373333 27.306667 71.68 27.306666s54.613333-10.24 75.093333-30.72c40.96-44.373333 40.96-109.226667 0-146.773333z" ></path>
                    </svg>
                </a>
            </div>
        )
    }
}

export default ToTop
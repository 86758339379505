import React from 'react'

import './wavemulti.css'

const WaveMulti = data => (
    // <svg className="waves-multi" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
    //         <path
    //             d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
    //     <g className="waves-multi-parallax">
    //         <use x="48" y="0" fill="rgba(255,255,255,0.7)" />
    //         <use x="48" y="3" fill="rgba(255,255,255,0.5)" />
    //         <use x="48" y="5" fill="rgba(255,255,255,0.3)" />
    //         <use x="48" y="7" fill="#fff" />
    //     </g>
    // </svg>

    <svg className="WaveMultiGroup" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
        <path fill="rgba(255,255,255,0.7)"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        <path fill="rgba(255,255,255,0.5)"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        <path fill="rgba(255,255,255,0.3)"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
        <path fill="rgba(255,255,255,1)"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
    </svg>

)

export default WaveMulti
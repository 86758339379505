import React, {Component} from 'react'

import './socialmediaicon.css'

class SocialMediaIcon extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            colorful: false
        }
    }

    handleMouseEnter = (elem) =>  {
        this.setState({ colorful: true })
    }

    handleMouseMove = (elem) => {
        if (window.innerWidth < 640) {
            return;
        }
        if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))) {
            elem.stopPropagation()

            let item = elem.target
            if(item && item.tagName == "I") {
                item = item.parentNode.parentNode
            }
            let itemRect = item.getBoundingClientRect()
            
            let offset = Math.abs(elem.clientX - itemRect.left) / itemRect.width
            
            let prev = item.previousElementSibling || null
            let next = item.nextElementSibling || null
    
            let scale = 0.4
            item.style.setProperty('--scale-factor', 1)
    
            if(prev) {
                prev.style.setProperty('--scale-factor', 1 + scale * Math.abs(offset - 1))
            }
    
            item.style.setProperty('--scale-factor', 1 + scale)
    
            if(next) {
                next.style.setProperty('--scale-factor', 1 + scale * offset)
            }
        }
    }

    handleMouseLeave = (elem) => {
        this.setState({ colorful: false })
    }

    render() {
        const colorStyle = {
            color: this.state.colorful ? `${this.props.color}` : "#b6bbc5"
        }
        
        return (
            <div className="SocialMediaIcon"
                onMouseEnter={this.handleMouseEnter} 
                onMouseMove={this.handleMouseMove}
                onMouseLeave={this.handleMouseLeave}>
                <link rel="stylesheet" href="//at.alicdn.com/t/c/font_1802141_kk39jwuzmbs.css" />
                <a href={this.props.href} title={this.props.title} target="_blank">
                    <i 
                    className={`iconfont ${this.props.fontclass}`}
                    style={colorStyle}></i>
                </a>
            </div>
        )
    }
}

export default SocialMediaIcon
import React from 'react'

import './socialmedias.css'
import SocialMediaIcon from './socialmediaicon'

class SocialMedias extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            colorful: false
        }
    }


    handleMouseLeave(e) {
        document.querySelectorAll('.SocialMediaIcon').forEach(li => {
            li.style.setProperty('--scale-factor', 1)
        })
    }

    render() {
        return (
            <div className="SocialMediaGroup" onMouseLeave={this.handleMouseLeave}>
                {this.props.data.map((edge, index)=>(
                    <SocialMediaIcon
                        key={index}
                        fontclass={edge.node.fontclass}
                        color={edge.node.color}
                        href={edge.node.url}
                        title={edge.node.title}
                    />
                ))}   
            </div>
        )
    }
}

export default SocialMedias